<template>
    <section class="h-100 pt-2 pr-2">
        <div class="row mx-0 h-100">
            <div class="menu-prinicipal h-100">
                <div class="row mx-0 align-items-center border-b-gris pr-2" style="height:54px;">
                    <div class="col my-auto">
                        <el-input v-model="buscar" clearable placeholder="Buscar por ID" size="small" class="br-20" @input="busqueda" />
                    </div>
                    <el-tooltip class="item" effect="light" content="Descargar ventas" placement="bottom">
                        <div class="btn-action cr-pointer border ml-2" @click="antesGenerar">
                            <i class="icon-file-download-outline f-20" />
                        </div>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" content="Filtro" placement="bottom">
                        <div class="btn-action border ml-2" @click="openFiltro">
                            <i class="icon-filter-outline f-20" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col px-0 border-r-gris pt-3" style="width:400px;height:calc(100% - 55px);">
                    <!-- listado pedidos -->
                    <div v-show="pedidos.length" class="row mx-3 px-3 br-8 border bg-whitesmoke d-middle-center" style="height:32px;">
                        <span>{{ paginate.total }} pedidos</span>
                    </div>
                    <div class="mb-2 overflow-auto custom-scroll" style="max-height: calc(100% - 87px);">
                        <div v-for="(ped, idx) in pedidos" :key="idx" class="row mx-0 d-middle cr-pointer my-2 border-bottom pb-2 text-general" @click="seleccionar_pedido(ped.id)">
                            <div :class="`mx-2 px-1 br-5 ${activo==ped.id?'bg-general':''}`" style="height:75px;" />
                            <div :class="`col-11 px-0 tres-puntos f-15 py-2 br-10 ${activo==ped.id?'bg-light-f5':''}`">
                                <div class="row mx-0">
                                    <div class="col-5 px-2 d-middle">
                                        <i v-show="ped.id_pedido != null" class="icon-account-plus  f-18" />
                                        <p class="tres-puntos text-general f-300">ID. {{ ped.id }}</p>
                                        <i v-show="ped.manual === 1" class="icon-warehouse text-pink f-18" />
                                    </div>
                                    <div class="col-7 px-1 tres-puntos text-general text-right">
                                        <el-badge v-show="ped.cant_mensajes > 0" :value="ped.cant_mensajes" class="item mx-2 mt-2 mr-4">
                                            <i class="icon-message-text-outline cr-pointer f-20 text-gr-general" />
                                        </el-badge>
                                        <i :class="`f-18 ${icon_estado(ped.estado)}`" />
                                        {{ estado_pedido(ped.estado) }}
                                    </div>
                                </div>
                                <div class="row mx-0 my-1">
                                    <div class="col-8 px-2 text-general f-600">
                                        <p class="tres-puntos">{{ convertMoney(ped.valor_final,ped.idm_moneda) }}</p>
                                    </div>

                                    <div class="col-4 px-1 f-13">
                                        {{ ped.fecha_entrega | helper-fecha('DD MMM hh:mm a') }}
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-9 px-2 d-middle">
                                        <p class="tres-puntos f-13">{{ ped.direccion }}</p>
                                    </div>
                                    <div class="col-3 px-1">
                                        <b class="mr-1 text-general f-500">
                                            {{ ped.cant_productos }}
                                        </b>
                                        <span class="f-13">Productos</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 justify-center">
                        <div class="col-12 px-0">
                            <el-pagination
                            v-show="pedidos.length"
                            background
                            class="text-center paginar-general_mini w-100"
                            :current-page="paginate.current_page"
                            :page-size="paginate.per_page"
                            layout="prev, pager, next"
                            :total="paginate.total"
                            @current-change="listar_pedidos"
                            />
                        </div>
                    </div>
                    <div v-if="pedidos.length === 0" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="110" src="/img/no-imagen/clock.svg" alt="" />
                            </div>
                            <p>Aún no has recibidos, cancelado o rechazado pedidos</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 col menu-prinicipal  h-100" style="width: calc(100vw - 560px);">
                <listadoHistorial />
            </div>
        </div>
        <filtro-pedidos ref="modalFiltro" :opcion="2" @filtrar="accion_filtro" />
        <modal ref="modalConfirmar" titulo="Generar Excel" mensaje="¿Desear descargar el archivo con formato decimales con punto o coma?" no-aceptar>
            <slot>
                <div class="row mx-0 justify-center">
                    <div class="col px-0">
                        <p class="text-general pl-3 f-14">Fecha de Entrega</p>
                        <el-date-picker
                        v-model="fecha_entrega"
                        class="w-100 mx-0"
                        type="daterange"
                        popper-class="date-picker-edit-popper"
                        range-separator="-"
                        start-placeholder="Inicio"
                        end-placeholder="Fin"
                        value-format="yyyy-MM-dd"
                        format="dd-MMM-yyyy"
                        />
                    </div>
                </div>
                <div class="row mx-0 py-3">
                    <div class="col-8">
                        <p class="text-general f-14">Tipo decimales</p>
                    </div>
                    <div class="col-6">
                        <el-radio v-model="tipo_decimal" :label="true">
                            Decimal con punto '.'
                        </el-radio>
                    </div>
                    <div class="col-6">
                        <el-radio v-model="tipo_decimal" :label="false">
                            Decimal con coma ','
                        </el-radio>
                    </div>
                </div>
            </slot>
            <template v-slot:excel>
                <export-excel
                v-if="$can('botones_abastecimiento_historial_descargar')"
                :data="pedidos_excel"
                :fields="json_fields"
                :before-generate="startDownload"
                :before-finish="finishDownload"
                worksheet="Ventas"
                :name="nombreArchivo"
                >
                    <button
                    v-loading="loading_excel"
                    type="button"
                    class="btn mx-2 btn-aceptar text-white br-10 border-0"
                    >
                        Descargar
                    </button>
                </export-excel>
            </template>
        </modal>
    </section>
</template>

<script>
import Estadisticas from "~/services/estadisticas";
import {mapGetters} from 'vuex'
import Vue from 'vue'
import excel from 'vue-excel-export'
import moment from 'moment';

Vue.use(excel)
export default {
    metaInfo: () =>({ title: 'Historial'}),
    name: 'IndexHistorialAdmin',
    sockets: {
        admin_pedidos_chats(value){ // Emite Cuando se escribe en tabla admin_pedidos_chats
            try {
                const {chat, pedidoChat} = JSON.parse(value)

                if(chat.created_by !== this.$usuario.id ){// cuando No tengo abierto  el chat del pedido actual entonces notifico
                // && parseInt(this.$route.params.id_pedido) !== chat.id_admin_pedido
                    if(pedidoChat.id === this.id_pedido){
                        // console.log('return false', this.sockets);
                        return false
                    }
                    let idx_pedido = this.pedidos.findIndex(e => e.id === pedidoChat.id)

                    if(idx_pedido >= 0){
                        let found = this.pedidos[idx_pedido]
                        found.cant_mensajes = found.cant_mensajes +1
                    }
                    if(this.pedido && this.pedido.id === chat.id_admin_pedido){
                        this.pedido.cant_mensajes = this.pedido.cant_mensajes + 1
                    }
                }

            } catch (e){
                this.error_catch(e)
            }
        }
    },
    components: {
        listadoHistorial: () => import('./listadoHistorial'),
    },
    data(){
        return {
            buscar: '',
            nombreArchivo: 'Ventas.xls',
            activo: -1,
            pagina_actual:1,
            roles_admin: ['admin','admin-vip'],
            fecha_filtro:[],
            tipo_filtro:0,
            estados_filtro:[],
            loading_excel: false,
            tipo_decimal: true,
            pedidos_excel: [],
            fecha_entrega: [moment().subtract(1, 'day').toDate(),moment().subtract(1, 'day').toDate()],
        }
    },
    computed:{
        ...mapGetters({
            pedidos: 'pedidos/pedidos_historial_admin/pedidos',
            paginate: 'pedidos/pedidos_historial_admin/paginate',
            id_pedido: 'pedidos/pedidos_historial_admin/id_pedido', // solo actualizar chat
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        rol(){
            return this.$usuario.rol_nombre
        },
        json_fields(){
            return {
                'ID': 'id',
                'Estado': 'estado',
                'Estado Nombre': 'nombre_estado',
                'Justificación': 'justificacion_cancelacion',
                'Motivo': 'motivo_cancelacion',
                'Instrucciones': 'instrucciones',
                'Dirección': 'direccion',
                'Otra Dirección': 'otra_direccion',
                'Entrega Fecha': 'entrega_fecha',
                'Entrega Horario': 'entrega_horario',
                'Cantidad Productos': 'cantidad_productos',
                'Valor Costo': 'valor_costo',
                'Valor Productos': 'valor_productos',
                'Valor Envio': 'valor_envio',
                'Valor Descuento': 'valor_descuento',
                'Valor Final': 'valor_final',
                'ID Cupón': 'id_cupon',
                'Valor Cupón': 'valor_cupon',
                'Cupón Nombre': 'cupon_nombre',
                'Distancía': 'distancia',
                'Calificación': 'calificacion',
                'Calificación Comentario': 'calificacion_comentario',
                'Pago Estado': 'pago_estado_nombre',
                'Pago Valor': 'pago_valor',
                'Pago Anulado': 'pago_anulado',
                'Pedido Manual': 'manual',
                'Cliente manual': 'digitado_cliente_nombre',
                'Cliente manual Teléfono': 'digitado_cliente_telefono',
                'Dispositivo': 'dispositivo_nombre',
                'ID Cedis': 'id_cedis',
                'Cedis Nombre': 'cedis_nombre',
                'Confirmado': 'confirmado_by',
                'Confirmado Nombre': 'confirmado_nombre',
                'ID Delivery': 'id_user_delivery',
                'Delivery Nombre': 'delivery_nombre',
                'Delivery Fin': 'delivery_fin',
                'ID Motivo Delivery': 'id_motivo_delivery',
                'Motivo Delivery': 'motivo_delivery_nombre',
                'ID Pedido Cliente': 'id_pedido',
                'ID Cliente': 'id_user_cliente',
                'Cliente Nombre': 'user_cliente_nombre',
                'Cliente Entrega Directa': 'cliente_entrega_directa',
                'ID vendedor': 'created_by',
                'Nombre vendedor': 'created_nombre',
                'Creación Fecha': 'created_at',
                'Editor': 'updated_by',
                'Editor Nombre': 'updated_nombre',
                'Edición Fecha': 'updated_at',
                'Devoluciones': 'devoluciones_cantidad',
                'Devoluciones Valor': 'devoluciones_valor',
                'Descartes': 'descarte_cantidad',
                'Descartes Valor': 'descarte_valor',
                'Mensajes': 'mensajes_chat',
            }
        }
    },
    watch:{
        id_cedis(val){
            this.$store.dispatch('pedidos/pedidos_historial_admin/set_data')
            this.listar_pedidos(1)
        }
    },
    mounted(){
        let that = this
        if(this.id_cedis === null){
            setTimeout(function(){
                that.listar_pedidos(1)
            }, 500);
        }else{
            that.listar_pedidos(1)
        }
    },
    methods: {
        busqueda(text){
            const change = () => {
                if(text.length > 1){
                    this.listar_pedidos(1)
                }else if(text.length === 0){
                    this.listar_pedidos(1)
                    this.seleccionar_pedido(null)
                }
            }
            this.delay(change)
        },
        async listar_pedidos(page){
            try {
                this.pagina_actual = page
                let params = {
                    cedi:this.id_cedis,
                    page:this.pagina_actual,
                    query:this.buscar,
                    fecha:this.fecha_filtro,
                    tipo:this.tipo_filtro,
                    estados:this.estados_filtro
                }
                await this.$store.dispatch('pedidos/pedidos_historial_admin/listar_pedidos',params)
            } catch (e){
                this.error_catch(e)
            }
        },
        seleccionar_pedido(id){
            this.activo = id
            this.$store.commit('pedidos/pedidos_historial_admin/set_id_pedido', id)
        },
        icon_estado(estado){
            switch (estado){
            case 4:
                return 'icon-shopping text-g-light'
            case 26:
                return 'icon-account-remove-outline text-general-red'
            case 27:
                return 'icon-cancel-circled-outline text-general-red'
            case 25:
                return 'icon-playlist-remove text-general-red'
            case 32:
                return 'icon-cancel-circled-outline text-general-red'
            default:
                return ''
            }
        },
        estado_pedido(estado){
            switch (estado){
            case 4:
                return 'Entregado'
            case 26:
            case 27:
                return 'Cancelado'
            case 25:
                return 'Rechazado'
            case 32:
                return 'Reclamo'
            default:
                return ''
            }
        },
        openFiltro(){
            this.$refs.modalFiltro.open()
        },
        accion_filtro(obj){

            this.fecha_filtro = obj.fecha
            this.tipo_filtro = obj.tipo
            this.estados_filtro = obj.estados

            this.listar_pedidos(1)
        },
        antesGenerar(){
            this.$refs.modalConfirmar.toggle()
        },
        async startDownload(){
            try {
                this.loading_excel = true
                let params = {
                    id_cedis: this.id_cedis,
                    id_moneda: this.id_moneda,
                    fecha_inicio: moment(this.fecha_entrega[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha_entrega[1]).format('Y-MM-DD'),
                }
                const { data } = await Estadisticas.descargarVentas(params)

                if (data.ventas.length){
                    this.nombreArchivo = `Ventas (${this.formatearFecha(params.fecha_inicio)} - ${this.formatearFecha(params.fecha_fin)}).xls`
                    data.ventas.forEach(el => {
                        el.valor_costo = this.formatearDecimalesSeparador(el.valor_costo, this.tipo_decimal, data.decimales)
                        el.valor_productos = this.formatearDecimalesSeparador(el.valor_productos, this.tipo_decimal, data.decimales)
                        el.valor_envio = this.formatearDecimalesSeparador(el.valor_envio, this.tipo_decimal, data.decimales)
                        el.valor_descuento = this.formatearDecimalesSeparador(el.valor_descuento, this.tipo_decimal, data.decimales)
                        el.valor_final = this.formatearDecimalesSeparador(el.valor_final, this.tipo_decimal, data.decimales)
                        el.valor_cupon = this.formatearDecimalesSeparador(el.valor_cupon, this.tipo_decimal, data.decimales)
                        el.devoluciones_valor = this.formatearDecimalesSeparador(el.devoluciones_valor, this.tipo_decimal, data.decimales)
                        el.descarte_valor = this.formatearDecimalesSeparador(el.descarte_valor, this.tipo_decimal, data.decimales)
                        el.otra_direccion = el.otra_direccion ? 'Si' : 'No'
                        el.manual = el.manual ? 'Si' : 'No'
                        el.delivery_fin = el.delivery_fin ? 'Si' : 'No'
                        el.cliente_entrega_directa = el.cliente_entrega_directa ? 'Si' : 'No'
                    });
                    this.pedidos_excel = data.ventas
                } else {
                    this.finishDownload()
                }
            } catch (error){
                console.log(error);
            } finally {
                this.loading_excel = false
            }
        },
        finishDownload(){
            this.loading_excel = false
            this.$refs.modalConfirmar.toggle()
            this.tipo_decimal = true
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-prinicipal{
    background: #fff;
}
</style>
